var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "product-card mb-0 position-relative" },
    [
      _vm.showRemove
        ? _c(
            "div",
            {
              staticClass: "position-absolute",
              staticStyle: { top: "0", right: "0" },
            },
            [
              _c("e-button-icon", {
                staticStyle: { padding: "12px" },
                attrs: { icon: "XIcon", variant: "danger" },
                on: { click: _vm.onRemoveItem },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.productPriceInfo.showPromotionImg
        ? _c("b-img", {
            staticClass: "position-absolute",
            staticStyle: { top: "-15px", left: "0px", "object-fit": "contain" },
            attrs: {
              src: _vm.promotionImg,
              width: "150",
              height: "150",
              alt: "Bandeira de que produto está em promoção",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "card-container" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center" },
          [
            _c("b-img", {
              staticClass: "rounded",
              staticStyle: { "object-fit": "contain" },
              attrs: {
                src: _vm.product.imageSquare || _vm.logoImg,
                width: "150",
                height: "150",
                alt: "Fluid image",
              },
            }),
          ],
          1
        ),
        _c("div", [
          _c("p", { staticClass: "mb-0 h6 font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.product.name) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt-auto" }, [
          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm._f("currency")(_vm.productPriceInfo.price)) + " "
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c("item-quantity-input", {
              attrs: { id: _vm.id, product: _vm.product },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }