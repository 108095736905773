<template>
  <b-media
    tag="li"
    class="bg-white p-1 shadow rounded"
    no-body
  >
    <b-media-aside class="mt-auto mb-auto">
      <div class="d-flex align-items-center">
        <b-button
          class="cart-item-button"
          style="border: 0px !important;"
          variant="outline-danger"
          @click="onRemoveItem"
        >
          <b-icon
            icon="trash"
            font-scale="1.5"
            text
          />
        </b-button>
        <b-img
          width="75"
          height="75"
          :alt="cartItem.skuProduct.name"
          :src="cartItem.skuProduct.imageSquare || logoImg"
          class="rounded"
          style="object-fit: contain;"
        />
      </div>
    </b-media-aside>

    <b-media-body>
      <b-row
        class="d-flex justify-content-between"
        style="min-height: 75px;"
      >
        <b-col
          md="8"
          class="d-flex flex-column"
        >
          <h5 class="mt-0 mb-1">
            {{ cartItem.skuProduct.name }}
          </h5>

          <div
            v-if="getKitItems.length > 0"
            class="mb-2"
          >
            <div
              v-for="(kitItem, index) in getKitItems"
              :key="`cartSidebar_${index}`"
              class="d-flex"
              style="font-size: 12px; gap: 5px;"
            >
              <p class="mb-0 font-weight-bolder w-100 text-right">
                {{ kitItem.quantity }}x
              </p>
              <p
                class="mb-0"
                style="min-width: 280px;"
              >
                {{ kitItem.skuProduct.name }}
              </p>
            </div>
          </div>

          <!-- price -->
          <div class="mt-auto d-flex justify-content-between">
            <div class="d-flex font-weight-bold">
              <p
                class="mb-0"
                style="margin-right: 5px"
              >
                {{ cartItem.quantity }}x
              </p>
              <p
                class="mb-0"
                style="margin-right: 5px"
              >
                <span
                  v-if="getItemPrice.hasCanceledPrice"
                  class="sale-items-canceled-price mr-2"
                >
                  {{ getItemPrice.canceledPrice | currency(null, 2) }}
                </span>
                <span> {{ getItemPrice.price | currency(null, 2) }} </span>
              </p>
            </div>
            <div>
              <p class="mb-0 font-weight-bold">
                {{ getItemPrice.netValue | currency(null, 2) }}
              </p>
            </div>
          </div>
        </b-col>
        <!-- /price -->

        <b-col
          md="4 "
          class="d-flex justify-content-end align-items-center"
          style="margin-top: 14px;"
        >
          <item-quantity-input
            id="cart_item"
            :product="cartItem.skuProduct"
          />
        </b-col>
      </b-row>
    </b-media-body>
  </b-media>
</template>

<script>
import {
  BButton,
  BIcon,
  BCol, BImg, BMedia, BMediaAside, BMediaBody, BRow
} from 'bootstrap-vue'
import logoImg from '@/assets/images/logo/echope_small.png'
import { mapActions } from 'vuex'
import { payBoxUtils } from '@/mixins'
import ItemQuantityInput from '../input/ItemQuantityInput.vue'

export default {
  components: {
    BMedia,
    BImg,
    BRow,
    BCol,
    BButton,
    BIcon,
    ItemQuantityInput,
    BMediaAside,
    BMediaBody,
  },

  props: {
    /** item da venda em pay-box */
    cartItem: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      logoImg,
    }
  },

  computed: {
    getItemPrice() {
      const { priceInfo, type, promotion } = this.cartItem.priceRuleSelected

      const resultItemPrice = {
        netValue: priceInfo.localNetValue,
        hasCanceledPrice: false,
        canceledPrice: 0,
        price: priceInfo.price,
      }

      if (priceInfo.localDiscountTotal > 0) {
        return {
          ...resultItemPrice,
          hasCanceledPrice: true,
          canceledPrice: priceInfo.originalPrice,
          price: priceInfo.priceInfo.price,
        }
      }

      if (type === payBoxUtils.priceRuleTypes.PROMOTION) {
        return {
          ...resultItemPrice,
          hasCanceledPrice: true,
          canceledPrice: promotion.priceFrom || priceInfo.unitValue,
          price: priceInfo.price,
        }
      }

      return resultItemPrice
    },

    getKitItems() {
      return this.cartItem.kitItems || []
    }
  },

  methods: {
    ...mapActions('pages/pdv/atm', { stRemoveItemFromAtm: 'removeItemFromAtm', }),

    async onRemoveItem() {
      const confirmed = await this.confirm({
        title: this.$t('Deseja remover o produto do carrinho?'),
        text: ' ',
      })
      if (!confirmed) {
        return
      }

      await this.stRemoveItemFromAtm(this.cartItem)
    },
  }
}
</script>

<style lang="scss" scoped>
.cart-item-button {
  height: 37px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sale-items-canceled-price {
  text-decoration: line-through;
  opacity: 0.8;
  color: var(--danger);
}
</style>
