var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-media",
    {
      staticClass: "bg-white p-1 shadow rounded",
      attrs: { tag: "li", "no-body": "" },
    },
    [
      _c("b-media-aside", { staticClass: "mt-auto mb-auto" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "cart-item-button",
                staticStyle: { border: "0px !important" },
                attrs: { variant: "outline-danger" },
                on: { click: _vm.onRemoveItem },
              },
              [
                _c("b-icon", {
                  attrs: { icon: "trash", "font-scale": "1.5", text: "" },
                }),
              ],
              1
            ),
            _c("b-img", {
              staticClass: "rounded",
              staticStyle: { "object-fit": "contain" },
              attrs: {
                width: "75",
                height: "75",
                alt: _vm.cartItem.skuProduct.name,
                src: _vm.cartItem.skuProduct.imageSquare || _vm.logoImg,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-media-body",
        [
          _c(
            "b-row",
            {
              staticClass: "d-flex justify-content-between",
              staticStyle: { "min-height": "75px" },
            },
            [
              _c(
                "b-col",
                { staticClass: "d-flex flex-column", attrs: { md: "8" } },
                [
                  _c("h5", { staticClass: "mt-0 mb-1" }, [
                    _vm._v(" " + _vm._s(_vm.cartItem.skuProduct.name) + " "),
                  ]),
                  _vm.getKitItems.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mb-2" },
                        _vm._l(_vm.getKitItems, function (kitItem, index) {
                          return _c(
                            "div",
                            {
                              key: "cartSidebar_" + index,
                              staticClass: "d-flex",
                              staticStyle: { "font-size": "12px", gap: "5px" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-bolder w-100 text-right",
                                },
                                [_vm._v(" " + _vm._s(kitItem.quantity) + "x ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0",
                                  staticStyle: { "min-width": "280px" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(kitItem.skuProduct.name) + " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-auto d-flex justify-content-between" },
                    [
                      _c("div", { staticClass: "d-flex font-weight-bold" }, [
                        _c(
                          "p",
                          {
                            staticClass: "mb-0",
                            staticStyle: { "margin-right": "5px" },
                          },
                          [_vm._v(" " + _vm._s(_vm.cartItem.quantity) + "x ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "mb-0",
                            staticStyle: { "margin-right": "5px" },
                          },
                          [
                            _vm.getItemPrice.hasCanceledPrice
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "sale-items-canceled-price mr-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getItemPrice.canceledPrice,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.getItemPrice.price,
                                      null,
                                      2
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.getItemPrice.netValue,
                                  null,
                                  2
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                  staticStyle: { "margin-top": "14px" },
                  attrs: { md: "4 " },
                },
                [
                  _c("item-quantity-input", {
                    attrs: {
                      id: "cart_item",
                      product: _vm.cartItem.skuProduct,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }